import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StationsView from '../views/StationsView.vue'
import StationNameView from '../views/StationNameView.vue'
import DailyStatsView from '../views/DailyStatsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "about" */ '../views/MapView.vue')
  },
  {
    path: '/stations',
    name: 'stations',
    component: StationsView
  },
  //dynamic for stations:
  {
    path: '/stations/:name_sta',
    name: 'station_name',
    component: StationNameView 
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: DailyStatsView
  },
  {
    path: '/coseismic',
    name: 'coseismic',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CoseismicView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/api',
    name: 'api'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }, // Redirect to the 'Home' route
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
