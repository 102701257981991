<template>
  
  <div :id="id_map">

    <!--<div class="search-container">
      <input type="text" id="search" placeholder="Search for a marker">
      <button onclick="searchMarker()">Search</button>
    </div>-->

  </div>

</template>

<script>
import MapPopup from './MapPopup.vue';
import { createApp, h } from "vue"

var L = window.L;

//set main color + header size css
var r = document.querySelector(':root');
r.style.setProperty('--igs-color', require('../assets/global.json').igscumulstaColor);
r.style.setProperty('--otherSta-color', require('../assets/global.json').otherstaColor);

export default {
  name: 'MapComp',
  props:{
    id_map: {default:"map"},
    addMiniMap: {default : true, type: Boolean},
    addImgPopup: {default : true, type: Boolean},
    centerSta: {default : ""} // if we need to center the map

  },
  data () {
    return {
      zoom: 2,
      center: [30, 0],
      minZoom: 2,
      maxZoom: 18,
      tileProviders: null,
      layers : null, // will be update at creation
      colormarker: require('../assets/global.json').headerColor,
      igscumul_color : require('../assets/global.json').igscumulstaColor,
      other_color : require('../assets/global.json').otherstaColor,
      dictStations: null,
      markers : {}
    };
  },
  methods: {
    click_marker_popup(e){
      var popup = e.target.getPopup();
      var name_sta = e.target.getTooltip().getContent();
      var add_imgPopup = this.addImgPopup;

      var PopupApp = createApp({ 
          setup () {
            return () => h(MapPopup, {name_sta: name_sta, addImgPopup: add_imgPopup}) //imgPopup added only on Map window, not on map of each station
          }
        })

      const wrapper = document.createElement("div")
      PopupApp.mount(wrapper)
      popup.setContent(wrapper)

    }, 
    customLegendColor(){
      //legend
      var list_labels = document.getElementsByClassName("leaflet-control-layers-overlays")[0].children; //normaly 2 elements : IGS and other
      
      ///////IGS
      var igslegend = list_labels[0]
      //text
      igslegend.getElementsByTagName("span")[1].style.color = this.igscumul_color
      //checkbox
      igslegend.getElementsByTagName("input")[0].style.accentColor = this.igscumul_color

      ///////others
      igslegend = list_labels[1]
      //text
      igslegend.getElementsByTagName("span")[1].style.color = this.other_color
      //checkbox
      igslegend.getElementsByTagName("input")[0].style.accentColor = this.other_color

      //minimap
      var minimap = document.getElementsByClassName("leaflet-control-worldminimap")
      if (minimap.length ==1){// minimap is defined
        minimap[0].style.backgroundColor = "#c4ddf1";
        minimap[0].style.borderColor = this.igscumul_color;
      }
      
      
    }
  },

  async mounted() {

    //// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    ////map layers

    var osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
      visible: true,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    })
    var topo = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',{
      visible: false,
      attribution:
        'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    })

    this.layers = {
    "OpenStreetMap": osm,
    "OpenTopoMap": topo
    };
    
    //// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // markers & IGS stations
    var api_address = process.env.VUE_APP_api_address;

    var response = await fetch(api_address+"/stations_coords", {
      method: 'get',
      headers:{
        "Access-Control-Allow-Origin": "*",
      }
    });

    var markersJson = await response.json();

    //r is the JSON with marker position
  
    this.dictStations = Object.values(markersJson);

    //init
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><
    // Style cluster  
    var igscumul_lg = L.markerClusterGroup({
      spiderfyDistanceMultiplier:2, //be sure to can click on spiderfy point
      maxClusterRadius:5,
      animate: false,
      //disableClusteringAtZoom: 5,
      //spiderfyOnMaxZoom: 2,
      polygonOptions: {color: '#007791'},
      iconCreateFunction: function (cluster) {
            var childCount = cluster.getChildCount();
            var c = ' marker-cluster-igs-';
            if (childCount < 10) {
              c += 'small';
            } 
            else if (childCount < 100) {
              c += 'medium';
            } 
            else {
              c += 'large';
            }
            return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', 
              className: 'marker-cluster' + c, iconSize: new L.Point(18, 18) });
            }
    })

    var others_lg = L.markerClusterGroup({
      spiderfyDistanceMultiplier:2,
      maxClusterRadius:5,
      animate: false,
      //disableClusteringAtZoom: 5,
      //spiderfyOnMaxZoom: 2,
      polygonOptions: {color: '#485257'},
      iconCreateFunction: function (cluster) {
        
            var childCount = cluster.getChildCount();
            var c = ' marker-cluster-other-';
            if (childCount < 10) {
              c += 'small';
            } 
            else if (childCount < 100) {
              c += 'medium';
            } 
            else {
              c += 'large';
            }
            return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', 
              className: 'marker-cluster' + c, iconSize: new L.Point(18, 18) });
            }
    })
    

    //Icon igs & other >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    var igs_icon = L.icon({
        iconUrl: '../assets/igs_popup_simple.png',
    //shadowUrl: 'leaf-shadow.png',

          iconSize:     [12, 18], // size of the icon
          //shadowSize:   [50, 64], // size of the shadow
          iconAnchor:   [6, 18], // point of the icon which will correspond to marker's location
          //shadowAnchor: [4, 62],  // the same for the shadow
          //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      });


      var other_icon = L.icon({
        iconUrl: '../assets/other_popup_simple.png',
    //shadowUrl: 'leaf-shadow.png',

          iconSize:     [10, 14], // size of the icon
          //shadowSize:   [50, 64], // size of the shadow
          iconAnchor:   [5, 14], // point of the icon which will correspond to marker's location
          //shadowAnchor: [4, 62],  // the same for the shadow
          //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      });

    var mark_obj;
    var center_station = L.layerGroup()
    
    for (var i = 0; i < this.dictStations.length; i++) {

      var mark = this.dictStations[i]

      if (mark.id == this.centerSta){

        //change map position center and zoom
        this.center = [mark.position.lat, mark.position.lng]
        this.zoom = 5

        //popup and plot on map
        mark_obj= L.marker(L.latLng(mark.position)).bindPopup('',{maxWidth : 'auto'})
        mark_obj.bindTooltip(mark.id).openTooltip(); // tooltip with name id

        //add event click to marker --> create and populate popup
        mark_obj.on('click',this.click_marker_popup)

        //add in Cluster
        if (mark.igscumul){
          igscumul_lg.addLayer(mark_obj)
        } else{ //other station
          others_lg.addLayer(mark_obj)
        }
        //add anyway to map...
        mark_obj.addTo(center_station)
        
      }

      // Create and save a reference to each marker. No popup for the moment, only tooltip
      else if (mark.igscumul){
        mark_obj= L.marker(L.latLng(mark.position),{icon: igs_icon}).bindPopup('',{maxWidth : 'auto'});
        mark_obj.bindTooltip(mark.id).openTooltip(); // tooltip with name id
        

        //add event click to marker --> create and populate popup
        mark_obj.on('click',this.click_marker_popup)
        //add on map
        igscumul_lg.addLayer(mark_obj)
      }else{
        mark_obj= L.marker(L.latLng(mark.position),{icon: other_icon}).bindPopup('',{maxWidth : 'auto'})
        mark_obj.bindTooltip(mark.id).openTooltip();

        //add event click to marker --> create and populate popup
        mark_obj.on('click',this.click_marker_popup)
        others_lg.addLayer(mark_obj)
      }
    }

    var map = L.map(this.id_map,{
      fullscreenControl: true,
      center: this.center,
      zoom: this.zoom,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      layers: [osm,  others_lg, igscumul_lg] //default layers: osm with all stations
      
    })
    var layerControl = L.control.layers(this.layers, {},{collapsed : !this.addMiniMap}).addTo(map);
    

    if (this.addMiniMap==true){
      L.control.worldMiniMap().addTo(map);
      
    }

    // add 2 overlays at layer control : igscumul stations and others
    layerControl.addOverlay(igscumul_lg, "Stations part of IGS cumulative solution");
    layerControl.addOverlay(others_lg,"Other stations (i.e., with short or sparse position time series)");

    //map.addLayer(L.layerGroup(igscumul_stations))

    this.customLegendColor()

    // refresh cluster after end of map construction
    igscumul_lg.refreshClusters();
    others_lg.refreshClusters();

    // add anyway center station 1st plan
    center_station.addTo(map)

    //add scale..
    L.control.scale({position: 'bottomright'}).addTo(map);

  }

}
</script>

<style>
.leaflet-container {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: left;
  font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif;
  
}


.marker-cluster div {
  width: 14px !important;
  height: 14px !important;
  margin-left: 2px !important;; /* Allow center with IconSize: width + 2*margin-left = iConSize (18px)*/
  margin-top: 2px !important;; 

}

.marker-cluster span {
  line-height: 14px !important;
}


/**Cluster colors */
.marker-cluster-igs-small {
background-color: var(--igs-color)
}
.marker-cluster-igs-small div {
background-color: #8bd2e7
}
.marker-cluster-igs-medium {
background-color: var(--igs-color)
}
.marker-cluster-igs-medium div {
background-color: #209fc5
}

.marker-cluster-igs-large {
background-color: var(--igs-color)
}
.marker-cluster-igs-large div {
background-color: #1180a1
}


.marker-cluster-other-small {
background-color: var(--otherSta-color)
}
.marker-cluster-other-small div {
background-color: #d1d6d8
}
.marker-cluster-other-medium {
background-color: var(--otherSta-color)
}
.marker-cluster-other-medium div {
background-color: #8d9091
}

.marker-cluster-other-large {
background-color: var(--otherSta-color)
}
.marker-cluster-other-large div {
background-color: #656969
}


/* CSS search bar*/
.search-container {
  position: absolute;
  top: 10px;
  text-align: center;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height:40px
}

#search {
  width: 70%;
  padding: 5px;
  margin-right: 5px;
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}


</style>
