<template>

  <div id="container_graphes_res"  >
    <div id="graph_res_E" ></div>
    <div id="graph_res_N" ></div>
    <div id="graph_res_H" ></div>
  </div>

</template>


<script>
import $ from 'jquery';
import {syncExtremes, syncExtremesY, verticaleLine, syncTooltip} from './HighchartsTools.js';
import {zip_data, convertDecimalDate} from './APITools.js'

/* eslint-disable */
export default {
  name: "GraphResidues",
  props:{
    name_sta:{
      type:String,
      default:"GOLD"
    }

  },
  //components: { highcharts: Chart },
  data() {
		return {
      loading: false, 
		}
	},
  methods:{

  formatterTooltip(){
    /**
     * Tooltip formatter
     */
    // The first returned item is the header, subsequent items are the
    // points
    var x =  new Date(this.x)
    //console.log(x, '<b>' +x.toString() + '</b>', this.points)
    return ["<div style='font-size:10px'>" + x.toLocaleDateString("en-US", optionsDates) + "</div>"].concat(
      this.points ?
          this.points.map(function (point) {
            if (point.y == null){return false}
                return point.series.name + ': <b>' + (point.y + offset*point.series.index).toFixed(3) + '</b>';
              
          }) : []
    );
    },

  plotGraphRes(response){
  // ylabel format
  //////>>>>>>>< Preformat
  // responsive graphes (when graph is ploted)
  var chartWidth = Math.floor(window.innerWidth/4.1)
  var chartHeight = 700;
  
  var offset = 20
  var optionsDates = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric'}
  var listCenters = response['ac']; // New labels
  var labENH = listCenters.map((value, index) =>{
      var result = index * - offset;
      if (Number.isInteger(result)) {
            return result; // Return the result as an integer
        } else {
            return result.toFixed(1); // Return the result as a string with one decimal place in case where 0.60000000001 for example
        }
      }
  ); // Original labels for E/ N (offset of 10) >> 0,-10,-20

  // Create a mapping between the original and new labels
  var labelMapping_ENH = {};
  var labelCustomMapping_ENH = {};
 
  for (var i = 0; i < listCenters.length; i++) {
    labelMapping_ENH[labENH[i]] = listCenters[i];
    labelCustomMapping_ENH[labENH[i]] = labENH[i];
    
  }

  // 0 center plotlines
  var plot_0ENH = labENH.map(function (value, index) {
                              return {
                                  value: value,
                                  color: "#808080",
                                  width: 2,
                                  zIndex: 0
                              };
                          });
  //// end preformat


  //init
   // empty chartOptions
  var chartOptions_E = {
          boost: {useGPUTranslation : true, usePreAllocated: true,seriesThreshold : 2},
          chart: {zoomType: 'xy',resetZoomButton: {position: {align: 'left',x:0,y:-10}}, width: chartWidth, height:chartHeight},
          series: [],
          title: {text: ''},
          tooltip: {
            formatter: function () {
            /**
             * Tooltip formatter
             */
            // The first returned item is the header, subsequent items are the
            // points
            var x =  new Date(this.x)
            //console.log(x, '<b>' +x.toString() + '</b>', this.points)
            return ["<div style='font-size:10px'>" + x.toLocaleDateString("en-US", optionsDates) + "</div>"].concat(
              this.points ?
                  this.points.map(function (point) {
                    if (point.y == null){return false}
                        return point.series.name + ': <b>' + (point.y + offset*point.series.index).toFixed(3) + '</b>';
                      
                  }) : []
              );
            }, split: true
          },
          xAxis:{
            crosshair:true,
            type: "datetime",
            events: {
                      setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremes(e, Highcharts, ["graph_res_"])
                      },
                    },
            plotLines:[]
          },
          yAxis: {
            max: offset,
            min:-offset*(response["ac"].length),
            title: {text: this.name_sta+' East residuals [mm]'},
            tickInterval: offset/4,

            // grid format 
            gridLineWidth: 0,
            labels: {
            formatter: function () {
                // Check if the label value is in list center, and display it, otherwise hide it
                if (listCenters.indexOf(labelMapping_ENH[this.value]) !== -1) {
                    return labelCustomMapping_ENH[this.value];
                } else {
                    return '';
                }
            },style: {fontSize: "0.7em"}},
            plotLines: plot_0ENH,
            events: {
                setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremesY(e, Highcharts, ["graph_res_"])
                      },
                    },
          },

        };

  var chartOptions_N = {
          boost: {useGPUTranslation : true, usePreAllocated: true,seriesThreshold : 2},
          chart: {zoomType: 'xy',resetZoomButton: {position: {align: 'left',x:0,y:-10}}, width: chartWidth, height:chartHeight},
          series: [],
          title: {text: ''},
          tooltip: {
            formatter: function () {
            /**
             * Tooltip formatter
             */
            // The first returned item is the header, subsequent items are the
            // points
            var x =  new Date(this.x)
            //console.log(x, '<b>' +x.toString() + '</b>', this.points)
            return ["<div style='font-size:10px'>" + x.toLocaleDateString("en-US", optionsDates) + "</div>"].concat(
              this.points ?
                  this.points.map(function (point) {
                    if (point.y == null){return false}
                        return point.series.name + ': <b>' + (point.y + offset*point.series.index).toFixed(3) + '</b>';
                      
                  }) : []
              );
            }, split: true
          },
          xAxis:{
            crosshair:true,
            type: "datetime",
            events: {
                      setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremes(e, Highcharts, ["graph_res_"])
                      },
                    },
            plotLines:[]
          },
          yAxis: {
            max: offset,
            min:-offset*(response["ac"].length),
            title: {text: this.name_sta+' North residuals [mm]'},
            tickInterval: offset/4,

            // grid format 
            gridLineWidth: 0,
            labels: {
            formatter: function () {
                // Check if the label value is in list center, and display it, otherwise hide it
                if (listCenters.indexOf(labelMapping_ENH[this.value]) !== -1) {
                    return labelCustomMapping_ENH[this.value];
                } else {
                    return '';
                }
            },style: {fontSize: "0.7em"}},
            plotLines: plot_0ENH,
            events: {
                setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremesY(e, Highcharts, ["graph_res_"])
                      },
                    },
          },

        };

  var chartOptions_H = {
          boost: {useGPUTranslation : true, usePreAllocated: true,seriesThreshold : 2},
          chart: {zoomType: 'xy',resetZoomButton: {position: {align: 'left',x:0,y:-10}}, width: chartWidth, height:chartHeight},
          series: [],
          title: {text: ''},
          tooltip: {
            formatter: function () {
            /**
             * Tooltip formatter
             */
            // The first returned item is the header, subsequent items are the
            // points
            var x =  new Date(this.x)
            //console.log(x, '<b>' +x.toString() + '</b>', this.points)
            return ["<div style='font-size:10px'>" + x.toLocaleDateString("en-US", optionsDates) + "</div>"].concat(
              this.points ?
                  this.points.map(function (point) {
                    if (point.y == null){return false}
                        return point.series.name + ': <b>' + (point.y + offset*point.series.index).toFixed(3) + '</b>';
                      
                  }) : []
              );
            }, split: true
          },
          xAxis:{
            crosshair:true,
            type: "datetime",
            events: {
                      setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremes(e, Highcharts, ["graph_res_"])
                      },
                    },
            plotLines:[]
          },
          yAxis: {
            max: offset,
            min:-offset*(response["ac"].length),
            title: {text: this.name_sta+' Up residuals [mm]'},
            tickInterval: offset/4,

            // grid format 
            gridLineWidth: 0,
            labels: {
            formatter: function () {
                // Check if the label value is in list center, and display it, otherwise hide it
                if (listCenters.indexOf(labelMapping_ENH[this.value]) !== -1) {
                    return labelCustomMapping_ENH[this.value];
                } else {
                    return '';
                }
            },style: { fontSize: "0.7em"}},
            plotLines: plot_0ENH,
            events: {
                setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremesY(e, Highcharts, ["graph_res_"])
                      },
                    },
          },



        };
  var yswitch = response["yswitch"]
  //update graphes:
  for (let center=0; center < response['ac'].length; center++){ //for each dim
    
    // data from response
    var y = response["y"];
    var y = y.map(convertDecimalDate)

    // offset transaltion for plot, if null stay null
    var trans_res_e = response["e"][center].map(value => {
        if (value === null) {
            return null; // If the value is null, keep it as null
        } else {
            return value - offset * center; // Modify the value
        }});
    var trans_res_n = response["n"][center].map(value => {
        if (value === null) {
            return null; // If the value is null, keep it as null
        } else {
            return value - offset * center; // Modify the value
        }});
    var trans_res_h = response["h"][center].map(value => {
        if (value === null) {
            return null; // If the value is null, keep it as null
        } else {
            return value - offset * center; // Modify the value
        }});

    //date zip
    var residues_e = zip_data(y,trans_res_e);
    var residues_n = zip_data(y,trans_res_n);
    var residues_h = zip_data(y,trans_res_h);

    /** Previous 
    var residues_e = zip_data(y,response["e"][center]);
    var residues_n = zip_data(y,response["n"][center]);
    var residues_h = zip_data(y,response["h"][center]);
    */
    
    chartOptions_E["series"].push({
        boostThreshold: 1,
        name: response["ac"][center],
        type:  'line',
        data: residues_e
        })
    
    chartOptions_N["series"].push({
       boostThreshold: 1,
        name: response["ac"][center],
        type:  'line',
        data: residues_n
        })
    
    chartOptions_H["series"].push({
        boostThreshold: 1,
        name: response["ac"][center],
        type:  'line',
        data: residues_h
    })
  

    //yswitch : verticale line
    chartOptions_E['xAxis']['plotLines'].push(verticaleLine(yswitch, 'repro3', 'right'))
    chartOptions_E['xAxis']['plotLines'].push(verticaleLine(yswitch, 'operational', 'left'))

    chartOptions_N['xAxis']['plotLines'].push(verticaleLine(yswitch, 'repro3', 'right'))
    chartOptions_N['xAxis']['plotLines'].push(verticaleLine(yswitch, 'operational', 'left'))

    chartOptions_H['xAxis']['plotLines'].push(verticaleLine(yswitch, 'repro3', 'right')),
    chartOptions_H['xAxis']['plotLines'].push(verticaleLine(yswitch, 'operational', 'left'))
    }
    Highcharts.chart("graph_res_E",chartOptions_E)
    Highcharts.chart("graph_res_N",chartOptions_N)
    Highcharts.chart("graph_res_H",chartOptions_H)
  },
  },

  async mounted() {

    this.loading=true;
    var api_address = process.env.VUE_APP_api_address;

    var response = await fetch(api_address+"/res/"+this.name_sta, {
          method: 'get',
          headers:{
            "Access-Control-Allow-Origin": "*",
          }
        }).then(response => response.json());
      this.plotGraphRes(response)

  // SYNCHRONIZED CHART
  $('#container_graphes_res').bind("mousemove mouseleave", function(e) {syncTooltip(e, Highcharts, ["graph_res_"])})


  }

}
</script>

<style>
.long_graph_1 {
  height:400px;
  width: 1200px;
}

.long_graph_2{
  height:800px;
  width: 600px;
}

.long_graph_3{
  height:700px;
  width: 400px;


}

#container_graphes_res{
  display: flex;
  align-items: center;
  justify-content: center;
}



</style>
