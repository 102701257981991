<template>

  <div id="container_graphes_coords_res">
    <div id="dim0">
      <div id="coords_0"><i><LoaderDiv class="dim_online"/> <p class="dim_online">Charts are loading...</p> </i></div>
      <div id="residues_0"><i></i></div>
    </div>

    <div id="dim1">
      <div id="coords_1"></div>
      <div id="residues_1"></div>
    </div>

    <div id="dim2">
      <div id="coords_2"></div>
      <div id="residues_2"></div>
    </div> 
  </div>

</template>


<script>
import $ from 'jquery';
import LoaderDiv from './LoaderDiv.vue';
import {syncTooltip, syncExtremes, verticaleLine} from './HighchartsTools.js';
import {zip_data, convertDecimalDate, zip_error_bar} from './APITools.js'

/* eslint-disable */
export default {
  name: "GraphCoord",
  props:{
    name_sta:{
      type:String,
      default:"GOLD"
    },
    div_container:{
      type:String,
      default:"html_container_coords_graphes"
    }

  },
  components: { LoaderDiv },
  data() {
		return {
      loading: false,      
		}
	},
  methods:{

  plotGraph(response, type_graph, no_model=false){
  /**
   * Plot graphes with Highcharts
   */
    //update graphes:
  // responsive graphes (when graph is ploted)
  var chartWidth = Math.floor(window.innerWidth/2.8)
  var chartHeight = Math.floor(chartWidth/1.8)

  //console.log(chartWidth, chartHeight)
  for (let dim=0; dim < Object.keys(response['dict_m']).length; dim++){ //for each dim
    
    // data from response
    var  y = response.y;
    var y = y.map(convertDecimalDate)

    var ymin = - response["mv"][dim];
    var ymax = response["mv"][dim];

    var yswitch = response["yswitch"]

    if(type_graph=="coords"){
    //document.createElement("div");
    var points = zip_data(y,response.dict_m[dim]["ry"]);
    var error = zip_error_bar(y,response.dict_m[dim]["ry"],response.dict_m[dim]["sv"]);
    ymin += Math.min.apply(null,response.dict_m[dim]["yc"]);
    ymax += Math.max.apply(null,response.dict_m[dim]["yc"]);
  
    if(response.igscumul_pickle==true){
      var model = zip_data(y,response.dict_m[dim]["yc"]);
    }else{ // no model, empty
      var model = []
    }
    
    }else if(type_graph=="residues"){
    //residues
    var points = zip_data(y,response.dict_m[dim]["v"]);
    var error = zip_error_bar(y,response.dict_m[dim]["v"],response.dict_m[dim]["sv"]);
    }
  
    // empty chartOptions
    var chartOptions = {
          
          boost: {useGPUTranslation : true, usePreAllocated: true},
          chart: {zoomType: 'xy', width: chartWidth, height:chartHeight},
          legend : {enabled: false},
          series: [],
          title: {text: ' '},
          tooltip: {valueDecimals: 2, shared:true, xDateFormat: "%a %e %B %Y"},
          xAxis:{
            crosshair: true,
            type: "datetime",
            events: {
                      setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremes(e, Highcharts, ["coords_","residues_"])
                      },
                    },
            plotLines:[]
          },
          yAxis:{}

        };

      if (dim==0 && type_graph=="coords"){//only for 1st graph
        chartOptions["title"] = {text: 'Detrended station position time series + model'}
        if (no_model){ //case where no pickel + pytrf data, only plot serie
          chartOptions["title"] = {text: 'Detrended station position time series'}
        }
      } else if(dim==0 && type_graph=="residues"){
        chartOptions["title"] = {text: 'Residual time series'}
      };

      ///////////////////////////////////////////////////////////////////////////////////////////////////
      
      chartOptions["yAxis"]= {
          min:ymin,
          max:ymax,
          startOnTick: false,
          title: {
            text:response.dict_m[dim]['rdims']+' [mm]',
          },
          events: {
            /** If we want to sync in Y, but seem not clear with huge amplitude/ offset gap difference between E,N,U ...
                setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremesY(e, Highcharts, ["coords_","residues_"])
                      },*/
                    },
        }
      ///////////////////////////////////////////////////////////////////////////////////////////////////
      // Add series
    
      chartOptions["series"] = []
     
      chartOptions["series"].push(     
      {boostThreshold: 1,
        zIndex:-1,
        name:'error bar',
        type:  'columnrange', //'columnrange',//'errorbar',//'arearange',
        color:"#808080",
        marker:{
          radius:0,
        },
        data: error,
     
      })

      chartOptions["series"].push(
        {boostThreshold: 1,
        zIndex:1,
        name:'point',
        lineWidth: 0,
        marker:{
          enabled: true,
          radius:2,
        },// no line
        color:"#000000",
        states: {
                hover: {
                    lineWidthPlus: 0
                }
            },
        data: points})

        if (type_graph=="coords"){
          chartOptions["series"].push({
          boostThreshold: 1,
          zIndex:5,
          lineWidth: 2,
          name:'model',
          type:'spline',
          color:"#FF0000",
          data: model,
          })
      }
      
    
    // yd (changement...)
    for(let step = 0; step < response['yd'].length; step++){
      chartOptions['xAxis']['plotLines'].push({
          zIndex: 5,
          color: 'red', // Color value
          dashStyle: 'longdashdot', // Style of the plot line. Default to solid
          value: convertDecimalDate(response['yd'][step]), // Value of where the line will appear
          width: 2, // Width of the line 
          label: { 
            text: response['cd'][step], // Content of the label.
            rotation:-90,
            x: -4,
            y: 20,// 0 is top axe
            align: 'right',
            style: {
                      color: 'red',
                      fontWeight: 'bold',
                      border: 'solid',
                  }
                },
          })
      }
    // yswitch
    //repro3
    chartOptions['xAxis']['plotLines'].push(verticaleLine(yswitch, 'repro3', 'right'))
    chartOptions['xAxis']['plotLines'].push(verticaleLine(yswitch, 'operational', 'left'))

    //add js div
    Highcharts.chart(type_graph+'_'+String(dim),chartOptions)
    
  
    }
  },
  },

  created() {
    // initialize empty chart list (avoid llop + zoom bugs)
    for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
                    var chart = Highcharts.charts[i];
                    if (chart !== undefined){
                    chart.destroy()}
    }
    },

  async mounted() {

    var api_address = process.env.VUE_APP_api_address;

        var response = await fetch(api_address+"/coord/"+this.name_sta, {
          method: 'get',
          headers:{
            "Access-Control-Allow-Origin": "*",
          }
        }).then(response => response.json());
    
    

    if (response.igscumul_pickle == true ){ //pickle : model plot & residues
      this.plotGraph(response, "coords")
      this.plotGraph(response, "residues")
    }else{ //no models, no residues reset residues div
      this.plotGraph(response, "coords", true) //no plot model (affect only graph title...)
      for (let dim=0; dim < Object.keys(response['dict_m']).length; dim++){
        document.getElementById("residues_"+dim).innerHTML = ""
        
      }
    }

    // SYNCHRONIZED CHART
    $('#container_graphes_coords_res').bind("mousemove mouseleave", function(e) {syncTooltip(e, Highcharts, ["coords_", "residues_"])})

  this.loading=false;
  },
 
}

</script>

<style>


.dim_online  {
  display: inline-block
}

#container_graphes_coords_res {
  display: flex;
  flex-direction: column;
}

#dim0, #dim1, #dim2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

</style>