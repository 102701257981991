<template>

<div v-if="isSearch"
    @click="isOpened = true"
    id="search_comp"
    class="nav-list">
        <input type="text" v-model="station" list="stationNames" :placeholder= "searchPlaceholder" id="searchTool">
        
        <datalist id="stationNames" >
            <option v-for="station in stationNames" :key="station">{{station}}</option>
        </datalist>
<button class="bx bx-search" id="myBtnSearch" @click="goToStation"></button>

</div>


</template>


<script>

export default {
  name: 'SearchTool',
  props: {
    //! Search
      isSearch: {
        type: Boolean,
        default: true,
      },
      searchPlaceholder: {
        type: String,
        default: 'Search a station...',
      },
      searchTooltip: {
        type: String,
        default: 'Search',
      },
  },
  data() {
    return {
      //stationNames:'',
      station: '',
      stationNames:[]
    }
  },
  async created () {
    var api_address = process.env.VUE_APP_api_address;
    var response = await fetch(api_address+"/stations", {
        method: 'get',
        headers:{
          "Access-Control-Allow-Origin": "*",
        }
      }).then(response => response.json())

    this.stationNames = Object.keys(response).sort();

    var input = document.getElementById("searchTool");
    input.addEventListener("keypress", function(event) {
    if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("myBtnSearch").click();
    }
    });
  },
  methods : {
      goToStation(){

          var name_sta = document.getElementById("searchTool").value;
    
          if (! (this.stationNames.includes(name_sta))){
            //station doesn't exist: wrong entry by user
            return
          }
          this.$router.push({name:'station_name', params:{'name_sta':name_sta}})
      }
  }
}


</script>


<style>
#stationNames {
    z-index: 999;
    max-height: 200px;
    overflow-y: auto;
    color: #ffffff;
    background-color: black;
   
}

#search_comp{
  margin-top: 20px;
  margin-bottom:40px
}

#myBtnSearch:hover{
  background-color: #ffffff;
  color: black;
  cursor:pointer;
}

</style>