<template>
  <div><i> <LoaderDiv class="dim_online"/> <p class="dim_online">Chart is loading...</p>  </i> </div>

</template>


<script>
import $ from 'jquery';
import LoaderDiv from './LoaderDiv.vue';
import {syncTooltip, syncExtremes, syncExtremesY, verticaleLine} from './HighchartsTools.js';
import {zip_data, convertDecimalDate} from './APITools.js'

/* eslint-disable */
export default {
  name: "Graph_res",
  props: {
    "api_path":{
      type:String
    },
    "dim":{
      type:String,
      default:''},
    "graph_lim":{//lim E, N or H
      type:Array,
      default:[null,null]
    },
    "offset":{
      type:Number,
      default:1
    },
    "ratio":{
      type:Number,
      default:1
    }
  },
  
  components: {LoaderDiv },
  data() {
		return {
      loading: false,
      g_lim : this.graph_lim   // default prop value 
		}
	},
  methods:{
    
  plotGraphRes(response){

  var chartHeight = 750;
  //width graph
  if (this.ratio==1){
    //default
    var chartWidth = 600;
    

  } else if (this.ratio==2){
    var chartWidth = Math.floor(window.innerWidth/2.8);

  } else if (this.ratio==3) {
    var chartWidth = Math.floor(window.innerWidth/4.1);

  }

  
  ///preformat
  var offset = this.offset
  var optionsDates = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric'}
  var listCenters = response['ac']; // New labels
  var labENH = listCenters.map((value, index) => {
      var result = index * - offset;
      if (Number.isInteger(result)) {
            return result; // Return the result as an integer
        } else {
            return result.toFixed(1); // Return the result as a string with one decimal place in case where 0.60000000001 for example
        }
      }
      
      );; // Original labels for E/ N (offset of 10) >> 0,-10,-20

  // Create a mapping between the original and new labels
  var labelMapping_ENH = {}; //center name must be value
  var labelCustomMapping_ENH = {} //custom label
 
  for (var i = 0; i < listCenters.length; i++) {
    labelMapping_ENH[labENH[i]] =  listCenters[i];
    labelCustomMapping_ENH[labENH[i]] = labENH[i];
  }

  //console.log(this.api_path, labelMapping_ENH)

  // 0 center plotlines
  var plot_0ENH = labENH.map(function (value, index) {
                              return {
                                  value: value,
                                  color: "#808080",
                                  width: 2,
                                  zIndex: 0
                              };
                          });

  //// end preformat

    var chartOptions = {
          boost: {useGPUTranslation : true, usePreAllocated: true,seriesThreshold : 2},
          chart: {zoomType: 'xy',resetZoomButton: {position: {align: 'left',x:0,y:-10}}, width: chartWidth, height:chartHeight},
          series: [],
          title: {text: ''},
          tooltip: {
            formatter: function () {
            /**
             * Tooltip formatter
             */
            // The first returned item is the header, subsequent items are the
            // points
            var x =  new Date(this.x)
            //console.log(x, '<b>' +x.toString() + '</b>', this.points)
            return ["<div style='font-size:10px'>" + x.toLocaleDateString("en-US", optionsDates) + "</div>"].concat(
              this.points ?
                  this.points.map(function (point) {
                    if (point.y == null){return false}
                        return point.series.name + ': <b>' + (point.y + offset*point.series.index).toFixed(3) + '</b>';
                      
                  }) : []
            );
        },
          split: true,},
          xAxis:{
            crosshair:true,
            type: "datetime",
            events: {
                      setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremes(e, Highcharts, [this.api_path])
                      },
                    },
            plotLines:[]
          },
          
          yAxis: {
            max: offset,
            min:-offset*(response["ac"].length),
            title: {text: response["yaxis_title"]},
            tickInterval: offset/4,
            offset: -5,

            // grid format 
            gridLineWidth: 0,
            labels: {
            formatter: function () {
                // Check if the label value is in list center, and display it, otherwise hide it
                if (listCenters.indexOf(labelMapping_ENH[this.value]) !== -1) {
                    return labelCustomMapping_ENH[this.value];
                } else {
                    return '';
                }
            },style: {fontSize: "0.7em"}},
            plotLines: plot_0ENH,
            events: {
                setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremesY(e, Highcharts, [this.api_path])
                      },
                    },
          },
        };


  //console.log("Graph lim : ",chartOptions.yAxis.min, chartOptions.yAxis.max, )
  var yswitch = response["yswitch"]
  
  //update graphes:
  for (let center=0; center < response['ac'].length; center++){ //for each dim
    
    // data from response
    var y = response["y"];
    var y = y.map(convertDecimalDate)

    //translate offset value
    var trans_res = response["r"][center].map(value => {
        if (value === null) {
            return null; // If the value is null, keep it as null
        } else {
            return value - offset * center; // Modify the value
        }});

    var residues = zip_data(y,trans_res);

    
    chartOptions["series"].push({
        yAxis:0,
        boostThreshold: 1,
        zIndex:center,
        name: response["ac"][center],
        type:  'line',
        data: residues
        })

    //yswitch : verticale line
    chartOptions['xAxis']['plotLines'].push(verticaleLine(yswitch, 'repro3', 'right'))
    chartOptions['xAxis']['plotLines'].push(verticaleLine(yswitch, 'operational', 'left'))
    }

    
    //insert graph inside this div
    if (this.dim==""){
      var div_id = "graph_" + this.api_path
    } else {
      var div_id = "graph_" + this.api_path + "_"+ this.dim
    }
    Highcharts.chart(div_id,chartOptions)
   
  },
  },

  async mounted() {
    
    this.loading=true;
    var api_address = process.env.VUE_APP_api_address;
    if (this.dim==""){
      var all_api_adress = api_address+"/"+this.api_path
    } else {
      var all_api_adress = api_address+"/"+this.api_path+"/"+this.dim
    }

    var response = await fetch(all_api_adress, {
          method: 'get',
          headers:{
            "Access-Control-Allow-Origin": "*",
          }
        }).then(response => response.json());

      //console.log(response);
      this.plotGraphRes(response)

    // SYNCHRONIZED CHARTS
    $('#graph_po_res').bind("mousemove mouseleave", function(e) {syncTooltip(e, Highcharts, ["po_res"])})
    $('#graph_por_res').bind("mousemove mouseleave", function(e) {syncTooltip(e, Highcharts, ["por_res"])})
    $('#graph_gc_res').bind("mousemove mouseleave", function(e) {syncTooltip(e, Highcharts, ["gc_res"])})


  
  }

}
</script>
