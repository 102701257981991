<template>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <a href="https://ign.fr/">
              <img src="../assets/IGN_logo.jpg" alt="logo_ign" class="imgFooter">
              <img src="../assets/logo-tutelle.gif" alt="logo_tutelle" class="imgFooter">
            </a>
            
          </div>
          <div class="col-md-6">
            <h5 style="color:gray">Links</h5>
            <ul class="no-bullets">
              <li><a href="ftp://igs-rf.ign.fr/pub" style="font-size: 12px;">FTP data</a></li>
              <li><a href="https://ign.fr/" style="font-size: 12px;">IGN</a></li>
              <li><a href="https://igs.org/" style="font-size: 12px;">IGS</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
</template>


<script>
  export default{
    name: 'FooterLogos'
  }
</script>
  
  
<style>
  footer {
    /*background-image: url("../assets/header_texture.png");*/
    background-color: #ffffff;
    padding: 30px 0;
  }
  
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .col-md-6 {
    width: calc(50% - 15px);
    margin-bottom: 0px;
  }
  .col-md-6 a {
    color : blue
  }
  
  .imgFooter {
    height:80px;
    margin : 0px 10px 0px 10px
  }

  ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
</style>
  