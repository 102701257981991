// This Toolbox provides usefull format for API data reformat > Highcharts data format

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//              Data serie reformat : tuple, etc.
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


/**
 * Reformat data to tuple : [(date,value)]
 * @param {Array} array1 Time serie
 * @param {Array} array2 Value
 * @returns tuple serie
 */
export function zip_data(array1,array2){
    var zip_data = array1.map(function(e,i){return [e, array2[i]]; });
    return zip_data
}

/**
 * 
 * @param {Array} y Time
 * @param {Array} value Point value
 * @param {Array} error Error  : sigma
 * @returns tuple serie
 */
export function zip_error_bar(y,value,error){//error is symetric: +-value
    var zip_data = y.map(function(e,i){if (value[i]!=null){return [e, value[i]-error[i], value[i]+error[i]]; }else{return [e,null]}});
    return zip_data
}

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//              Date functions > reformat for graph, Unix timestamp, decimal year, etc.
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

/**
 * Provides if a year is leap (true) or not. Intermediate function for Decimal date conversion
 * @param {Int} year 
 * @returns Bool
 */
function leapYear(year) {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

/**
 * Provides date to decimal year format > Unix time format
 * @param {Float} decimalDate 
 * @returns 
 */
export function convertDecimalDate(decimalDate) {
    var year = parseInt(decimalDate);
    var reminder = decimalDate - year;
    var daysPerYear = leapYear(year) ? 366 : 365;
    var miliseconds = reminder * daysPerYear * 24 * 60 * 60 * 1000;
    var yearDate = new Date(year, 0, 1);
    return new Date(yearDate.getTime() + miliseconds).getTime();
}