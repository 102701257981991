<template>

  <MenuBoxText />
  
  <section class="boxsection boxtxt" id="Introduction">
    <h1>Introduction</h1>
    This site gives informations about a subset of products of the 
    <a href="https://igs.org/">International GNSS Service (IGS)</a>: final station positions, Earth Rotation Parameters (ERPs) and coordinates of the apparent geocenter. This introductory page provides general background information.<br>
    <br>
    The IGS collects data from a global network of more than 500 permanent GNSS stations. Several Analysis Centers (ACs) routinely generate various products from these data. The <a href="https://www.igs.org/acc/">Analysis Center Coordinator (ACC)</a> has overall responsibility for generating the main official IGS combined products from the AC products.<br>
    <br>
    The combination of the IGS final products, performed on a weekly basis, involves two main steps:
    <ul> 
        <li>Products related to the terrestrial frame, including ERPs, are obtained by a combination of SINEX files submitted by the ACs. This work is the main task of the <a href="https://igs.org/wg/reference-frame/">IGS Reference Frame Working Group (RFWG)</a>. <strong>This site is dedicated to that particular set of products.</strong></li>
        <li>Satellite orbits and clocks are then combined by the ACC (currently <a href="https://www.ga.gov.au/">Geoscience Australia</a> & <a href="https://www.mit.edu/">Massachusetts Institute of Technology </a>). </li>
    </ul>
</section> 


<section class="boxsection boxtxt" id="Daily_and_weekly_solutions">
    <h1>Daily and weekly solutions</h1>
    Each week, ten IGS ACs submit daily SINEX solutions including station positions and ERPs. These solutions are combined to form the IGS daily combined solutions. Those include:
    <ul>
        <li>daily combined station positions,</li>
        <li>daily combined ERPs,</li>
        <li>daily combined coordinates of the apparent geocenter.</li>
    </ul>
    <br>
    The daily combined solutions are then stacked to form the IGS weekly combined solution, which includes:
    <ul>
        <li>weeky combined station positions,</li>
        <li>daily combined ERPs,</li>
        <li>weekly combined coordinates of the apparent geocenter.</li>
    </ul>
    <br>
    The combination strategy is described in <a href="https://link.springer.com/article/10.1007/s00190-016-0897-6">Rebischung et al. (2016).</a> An independent combination of the daily AC SINEX solutions is also performed by the MIT Global Network Associate Analysis Center (GNAAC). It is used for comparison and validation of the IGS SINEX combination.<br>
    <br>
    The daily and weekly combined solutions for week N are usually made available on Thursday of week N+2 (delay of 12 days for the last day of the week to 18 days for the first day of the week).
</section>

<section class="boxsection boxtxt" id="Cumulative_solution">
    <h1>Cumulative solution</h1>
    Besides the daily and weekly combined solutions, a long-term cumulative solution is updated and released every 8 weeks. In that solution, piecewise linear (i.e., successive positions+velocities) models, plus post-seismic deformation models where necessary, are used to describe station motions. It is obtained by a long-term stacking of the daily IGS <a href="http://acc.igs.org/repro3/repro3.html">repro3</a> combined solutions from GPS week 730 (January 1994) to 2237 (November 2022) and the daily IGS operational solutions from GPS week 2238 to present.
</section>

<section class="boxsection boxtxt" id ="IGS_Reference_Frames">
    <h1>IGS Reference Frames</h1>
    Another task of the RFWG is the definition and maintenance of the Terrestrial Reference Frames to which all IGS products are referred. The latest IGS Reference Frame, <a href="https://lists.igs.org/pipermail/igsmail/2022/008234.html">IGS20</a>, came in use on GPS week 2238 (November 27, 2022). It is aligned in origin, scale and orientation to the latest release of the International Terrestrial Reference Frame, <a href="https://itrf.ign.fr/en/solutions/ITRF2020">ITRF2020</a>. For a complete chronology of the IGS Reference Frames, see <a href="https://igs.org/wg/reference-frame#documents">https://igs.org/wg/reference-frame#documents.</a>
</section>


<section class="boxsection boxtxt" id="Products">
    <h1>Products</h1>
    The products issued weekly by the IGS Reference Frame Working Group are the following:
    <ul>
        <li>IGS0OPSSNX_yyyyddd0000_01D_01D_SOL.SNX: Daily combined SINEX solution</li>
        <li>IGS0OPSSNX_yyyyddd0000_01D_01D_CRD.SNX: Daily combined SINEX solution without covariance matrix</li>
        <li>IGS0OPSSNX_yyyyddd0000_01D_01D_RES.SUM: Daily "AC - IGS" combination residuals</li>
        <li>IGS0OPSSNX_yyyyddd0000_01D_01D_RES.YML: Daily "AC - IGS" combination residuals in YAML format</li>
        <li>IGS0OPSSNX_yyyyddd0000_07D_07D_SOL.SNX: Weekly combined SINEX solution</li>
        <li>IGS0OPSSNX_yyyyddd0000_07D_07D_SOL.SNX: Weekly combined SINEX solution without covariance matrix</li>
        <li>IGS0OPSSNX_yyyyddd0000_07D_01D_ERP.ERP: Weekly ERP file</li>
        <li>IGS0OPSSNX_yyyyddd0000_07D_07D_SUM.SUM: Weekly combination summary</li>
        <li>IGS0OPSSNX_yyyyddd0000_07D_07D_SUM.SUM: Weekly combination summary in YAML format</li>
    </ul>
    where "yyyy" stands for the year number and "ddd" for the day of year [001-366] (first day of the week in case of weekly files).
    <br>
    <br>
        The products issued every 8 weeks after each update of the IGS cumulative solution are:
    <ul>
        <li>IGS0OPSSNX_1994002_yyyyddd_00U_SOL.SNX: Cumulative SINEX solution</li>
        <li>IGS0OPSSNX_1994002_yyyyddd_00U_CRD.SNX: Cumulative SINEX solution without covariance matrix</li>
        <li>IGS0OPSSNX_1994002_yyyyddd_00U_SUM.SUM: Long-term stacking summary</li>
    </ul>

    where "yyyy" stands for the year number and "ddd" for the day of year [001-366] (last day of the week).
    <br>
    <br>
    These products are available at the <a href="https://igs.org/data-access/#global-dcs">IGS Global Data Centres</a>. Other by-products of the SINEX combinations, such as time series of station positions, combination residuals and statistics are available through this website, or directly at <a href="ftp://igs-rf.ign.fr/pub">ftp://igs-rf.ign.fr/pub.</a>
</section>

<FooterLogos />



</template>

<script>
// @ is an alias to /src
import MenuBoxText from '@/components/MenuBoxText.vue'
import FooterLogos from '@/components/FooterLogos.vue';

//console.log("Current NODE ENV:", process.env.NODE_ENV, "API:", process.env.VUE_APP_api_address)
export default {
  name: 'HomeView',
  components: {
    MenuBoxText,
    FooterLogos
}
};
</script>
