import { convertDecimalDate } from "./APITools";

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//             Highcharts events (tooltip + resizeZoom)
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

/**
 * Provides qync tooltip callback
 * @param {event} e event mouseover
 * @param {Highcharts} Highcharts obj, list of chart inside current page
 * @param {list str} id_group_div list global id indiv of group, usefull to imit impact of other charts useless on page (ex: ["coords_","residues"])
 * @returns 
 * 
 */
export function syncTooltip(e, Highcharts,id_group_div){
  
      // ref chart, to set ref xValue according to event.chartX
      var ref_chart = true
      
      for (let i = 0; i < Highcharts.charts.length; ++i) {
                
              let chart = Highcharts.charts[i];
            
              if (chart!==undefined){
                let apply  = id_group_div.some(id => chart.renderTo.id.includes(id))
                
                if (apply){// we can apply event on this chart
                
                    let event = chart.pointer.normalize(e.originalEvent); // Find coordinates within the chart
                                                  
                    if (ref_chart){//1st chart as ref
                        ref_chart = false
                        // xValue corresponding to px
                        var ref_xValue = chart.xAxis[0].toValue(((event.chartX % chart.chartWidth) + chart.chartWidth) % chart.chartWidth)
                            //console.log("ref_xValue", new Date(ref_xValue), event.chartX, chart.xAxis[0].toPixels(ref_xValue))
                      }
                      //console.log(chart.renderTo.id, new Date(ref_xValue), event.chartX)    
                      //console.log("-----------",chart.renderTo.id, "-----------")
                      //console.log("Before %", event, event.chartX, chart.xAxis[0].toPixels(event.clientX))
                      event.chartX = chart.xAxis[0].toPixels(ref_xValue)//integer conversion
                
                      let point;
                      for (let j = 0; j < chart.series.length && !point; ++j) {
                            point = chart.series[j].searchPoint(event, true);
                          }
                            
                      if (!point){
                          return;}
                          
                      if (e.type === "mousemove") {
                          point.onMouseOver();
                          chart.xAxis[0].drawCrosshair(event, point);
                      } else {
                          point.onMouseOut();
                          chart.tooltip.hide(point);
                          chart.xAxis[0].hideCrosshair();
                      }
                }
          }
        }

        Highcharts.Pointer.prototype.reset = function () {return undefined}
      }



/**
 * Provides set extrem event, for div with id_group_div word (ex: ["coords_","residues"])
 * @param {event} e 
 * @param {Highcharts} Highcharts 
 * @param {list str} id_group_div 
 */

export function syncExtremes(e, Highcharts, id_group_div) {
  var thisChart = e.target.chart
  if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart!== undefined){
          //bool true if chart belong to coords or res graphes
          let apply  = id_group_div.some(id => chart.renderTo.id.includes(id))
          //var idChart = chart.renderTo.id
           
                    
          if (chart !== thisChart && apply) {
              if (chart.xAxis[0].setExtremes) { // It is null while updating
                  chart.xAxis[0].setExtremes(
                      e.min,
                      e.max,
                      undefined,
                      false,
                      { trigger: 'syncExtremes' }
                  );
              // show reset zoom button
               if( !chart.resetZoomButton ) {
                    chart.showResetZoom();
                }
              }
        }
      }
      });
    
  }
}


export function syncExtremesY(e, Highcharts, id_group_div) {
  
  if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart!== undefined){
          //bool true if chart belong to coords or res graphes
          let apply  = id_group_div.some(id => chart.renderTo.id.includes(id))
          //var idChart = chart.renderTo.id
          //console.log("syncY", apply)

                    
          if (apply) {
              if (chart.yAxis[0].setExtremes) { // It is null while updating
                
                chart.yAxis.map( function(ax){
                    ax.setExtremes(
                      e.min,
                      e.max,
                      undefined,
                      false,
                      { trigger: 'syncExtremes' }
                  )
                    })
              // show reset zoom button
               if( !chart.resetZoomButton ) {
                    chart.showResetZoom();
                }
              }
        }
      }
      });
    
  }
}


//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//             Highcharts plot
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

/**
 * Plot vertical line
 * @param {Array} y date where plot verticale line (xAxis)
 * @param {*} label label of this verticale line
 * @param {*} align_label position of the label ('right' or 'left')
 * @returns 
 */
export function verticaleLine(y, label, align_label){
  var x_value
  var rotation = -90
  if(align_label=='right'){
    x_value = -5
  } else if (align_label=='left'){
    x_value = 15
  }
  return {
      zIndex: 0,
      color: require('../assets/global.json').headerColor, // Color value
      //dashStyle: 'longdashdot', // Style of the plot line. Default to solid
      value: convertDecimalDate(y), // Value of where the line will appear
      width: 3, // Width of the line,
      label: { 
        text: label, // Content of the label.
        rotation: rotation,
        x: x_value,
        y: 40,
        //y: ymin+0.95*ymax,
        align: "right", //align right anyway
        style: {
                  color: require('../assets/global.json').headerColor,
                  fontWeight: 'bold',
                  fontSize: '11px'
              }
            },
      }
}
