<template>

    <MenuBoxText  v-if="api_load"/>
  
    <section class="boxsection boxtxt box_station" id="Information">

    <div class="dim_online" style="vertical-align: top; margin-top:80px; margin-right: 20px">
      <h1 style="font-size:3.5em;" class="color_sta" >{{ $route.params.name_sta }}</h1>
      
      <p>Latitude:<b>{{ sta_params.position.lat.toFixed(3) }}</b>; Longitude:<b>{{ sta_params.position.lng.toFixed(3) }} </b></p>
      <div id ="start_sta_info">
        The sitelog of this station is available <a id="a_sitelog">here</a>.
      </div>
      
      <div v-if="sol_list_options.length != 0">
        <br>
        This station is part of: <span class="igs_station">{{ sol_list_options.join(", ") }}</span>
      </div>
    </div>

    <div class="dim_online"  >
      <MapComp id_map="sta_map" :addMiniMap="false" :addImgPopup="false" :centerSta="$route.params.name_sta" class="box_station"/>
    </div>

    </section>

    

    <section class="boxsection boxgraph box_station" id="Position_time_series">
    <div class="boxtxtInboxsection">
      <h1 class="color_sta">Station position time series</h1>
      The <p style="display:inline" v-if="sol_list_options.length != 0">left</p> panel below shows daily position estimates of station <p class="stationName color_sta">{{ $route.params.name_sta }}</p> extracted from the daily IGS combined solutions (repro3 up to GPS week 2237 / operational afterwards). The corresponding time series is available <a id="a_timeSerieFile" href="">here</a>. A mean linear trend is removed for better legibility, as well as possible outliers.<br>
      <br>

      <div v-if="sol_list_options.length != 0">
        The superimposed red line is a fit composed of:
        <ul>
            <li>a piecewise linear function (with the dates of position & velocity changes taken from the <a href="ftp://igs-rf.ign.fr/pub/discontinuities/soln.snx">IGS discontinuity list</a>);</li>
            <li>annual and semi-annual sine waves;</li>
            <li>exponential and/or logarithmic functions describing post-seismic deformation, when needed (fixed to those given <a href="ftp://igs-rf.ign.fr/pub/psd/psd_IGS.snx">here</a>).</li>
        </ul>
        <br>
        The right panel below shows the residuals of that fit.

        If you spot an offset in the residual time series below, which is absent from the IGS discontinuity list, please contact <i>igs-rf (at) ign.fr</i>.
      </div>

    </div>
    
    <GraphCoord :name_sta="$route.params.name_sta" class="boxgraphInboxsection" />

  </section> 
    <!--<BoxText htmlContent='./StationNametext.html' :stationName='$route.params.name_sta' />-->
    
    <section class="boxsection boxtxt box_station" id="Get_coordinates"  v-if="sol_list_options.length != 0">
      <h1 style="display:inline">Get station coordinates: </h1>
      <FormStaCoords :name_sta="$route.params.name_sta" :sol_list_options="sol_list_options" v-if="sol_list_options.length != 0"/> <!--Async : await [] not empty-->
    </section>

    <section class="boxsection boxgraph box_station" id="Residuals">
      <div class="boxtxtInboxsection">
        <h1 class="color_sta">“AC – IGS” residuals</h1>
        The figures below show the “AC – IGS” position residuals of station <p class="stationName color_sta"> {{ $route.params.name_sta }} </p> from the daily IGS SINEX combinations, i.e., the difference between the AC and IGS combined station position estimates once reference frame differences have been removed.
        The corresponding time series are available <a href="ftp://igs-rf.ign.fr/pub/res/">here</a>.   
      </div>
      <GraphResidues :name_sta="$route.params.name_sta" class="boxgraphInboxsection" />
    
    </section>

    
    
</template>

<script>
// @ is an alias to /src
//import BoxText from '@/components/BoxText.vue'
import MenuBoxText from '@/components/MenuBoxText.vue';
import FormStaCoords from '@/components/FormStaCoords.vue';
import GraphCoord from '@/components/GraphCoord.vue';
import GraphResidues from '@/components/GraphResidues3.vue'
import MapComp from '@/components/MapCompCluster.vue';

export default {
  name: 'StationNameView',
  components: {
    //BoxText,
    GraphCoord,
    GraphResidues,
    FormStaCoords,
    MapComp,
    MenuBoxText
},

data() {
        return {
            api_address : process.env.VUE_APP_api_address,
            sol_list_options: [],
            sol_list_options_default: ["IGS20", "IGb14", "IGS14", "IGS cumulative solution"],
            api_load: false, //await async request 
            sta_params: {position:{lat:0,lng:0}}
        }
      },

async mounted(){
  
  //set main color + header size css
  var r = document.querySelector(':root');
  r.style.setProperty('--igs-color', require('../assets/global.json').igscumulstaColor);
  r.style.setProperty('--otherSta-color', require('../assets/global.json').otherstaColor);

  var sta_name = this.$route.params.name_sta;

  const a_ref = document.getElementById("a_timeSerieFile");
  a_ref.setAttribute("href", "ftp://igs-rf.ign.fr/pub/crd/"+sta_name+"_igs.plh");

  
  /*  
  Formextractcoord, get station options available from API (wich solution is available ?)
  */
  this.sta_params = await fetch(this.api_address+"/stations_coords/"+sta_name, {
        method: 'get',
          headers:{
            "Access-Control-Allow-Origin": "*",
          }
        }).then(response => response.json());

  //adapt main color of station page : --igs-color or --otherSta-color
  var elements = document.querySelectorAll('.color_sta');
  if (!this.sta_params["igscumul"]) {
  for (var i = 0; i < elements.length; i++) {
    elements[i].style.color = require('../assets/global.json').otherstaColor // Change the color to otherStaColor
    }
  //main background color change
  var home = document.querySelectorAll('.home')[0]
  home.style.backgroundColor = "#a8a3a33b"
  }

  elements = document.querySelectorAll('.box_station');
  if (!this.sta_params["igscumul"]) {
  for (i = 0; i < elements.length; i++) {
    elements[i].style.boxShadow = "3px 3px 11px 1px " + require('../assets/global.json').otherstaColor // Change the color to red
    }
  }

  this.sol_list_options = this.sta_params["solutions"]
  //update "IGS" by "IGS cumulative combination" if it is in list
  this.sol_list_options = this.sol_list_options.map(function(option) {
        if (option === "IGS") {
             return "IGS cumulative solution";
           }
        return option;
       });

  //sort according to default order
  var opt_default = this.sol_list_options_default

  this.sol_list_options.sort(function(a, b) {
      return opt_default.indexOf(a) - opt_default.indexOf(b);
      });

  //now we can add MenuBoxText
  this.api_load = true

  /*
  sitelog
  */
  var response = await fetch(this.api_address+"/sitelog/"+this.$route.params.name_sta, {
            method: 'get',
            headers:{
              "Access-Control-Allow-Origin": "*",
            }
          }).then(response => response.text());

  const a_sitelog = document.getElementById("a_sitelog");
  a_sitelog.setAttribute("href", response);

},


unmounted () { //prevent leak of memories with to many Highcharts.charts
  this.$router.go(this.$router.currentRoute)
}


};
</script>

<style>
.stationName{
  display: inline;
  font-weight: bold;
  }

#sta_map {
  height:300px;
  width: 400px
}

#Information{
  text-align: center;
}

.color_sta{ /*change text page color if station part of IGS sol or not */
  color: var(--igs-color)
}

/*<!--Class station colors-->*/
.igs_station{
  color: var(--igs-color);
  font-weight: bold;
}

.other_station{
  color: var(--otherSta-color);
  font-weight: bold;
}

.box_station{/*change box shadow if station part of IGS sol or not */
  box-shadow: 3px 3px 11px 1px var(--igs-color);
}


</style>
