<template>
  <div class="div_popup_map">

    <div style="display: flex; align-items: center;">
      <a :href="img_src" target="_blank" title="Zoom in time series graphs">
        <h1 :class="igsClass"><i class='bx bx-zoom-in'></i></h1>
      </a>
      <a :href="a_href" title="Visit the station page">
        <h1 :class="igsClass"><div class="popup_title"> {{ name_sta }} </div></h1>
      </a>
    </div>


    <a :href="a_href" title="Visit the station page" v-if="addImgPopup">
      <img id="img_popup" :src=img_src>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MapPopup',
  props: {
    'name_sta':{default:"GOLD"},
    'addImgPopup': {default : true, type: Boolean}, //no add in case of miniMap of each station
  },
  data() {
    return{
    api_address : process.env.VUE_APP_api_address,
    img_src : process.env.VUE_APP_api_address + "/img/" + this.name_sta,
    a_href : "/stations/"+ this.name_sta,
    igsClass: 'igs_station'// or 'other_station'
    }
  },

  async mounted() {
    // to change popup main color according to type: part of igs cumulative station or no
    // ask to API
    var sta_params = await fetch(this.api_address+"/stations_coords/"+this.name_sta, {
        method: 'get',
          headers:{
            "Access-Control-Allow-Origin": "*",
          }
        }).then(response => response.json());

  if (sta_params["igscumul"]) {//igscumul station >> blue Color
    this.igsClass = 'igs_station'
  }else{ //other station >> black
    this.igsClass = 'other_station'
  }

  }

}
</script>

<style>
#img_popup{
    width: 600px;
    height: 380px;
}

.div_popup_map a{
  text-decoration: none;

}

.popup_title:hover{
  color:rgb(183, 187, 187);
}

.div_popup_map i:hover{
    -ms-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
}


</style>