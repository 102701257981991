<template>
  <LegendStations />
  <ListStations />
</template>

<script>
// @ is an alias to /src
import ListStations from '@/components/ListStations.vue';
import LegendStations from '@/components/LegendStations.vue';

export default {
  name: 'StationsView',
  components: {
    ListStations,
    LegendStations
  }
}
</script>
