<template>

  <MenuBoxText  />
  
  <section class="boxsection boxgraph" id="Station_position_residuals">
    <div class="boxtxtInboxsection">
      <h1>Station position residuals</h1>
      The figure below shows weighted root mean squares (WRMS) of the “AC – IGS” station position residuals from the daily IGS SINEX combinations, i.e., the global level of agreement between the AC and IGS combined station positions once reference frame differences have been removed.
      The WRMS time series are low-pass filtered with a 10 cpy cutoff frequency for clarity. The corresponding time series are available <a href="ftp://igs-rf.ign.fr/pub/sum/">here</a>. 
    </div>

    <div id="container_graphes_wrms">
      <GraphWRMS dim="E" :graph_lim="[0,5]" id="graph_wrms_E" class="boxgraphInboxsection long_graph_1" ref="wrmsComponent" />
      <GraphWRMS dim="N" :graph_lim="[0,5]" id="graph_wrms_N" class="boxgraphInboxsection long_graph_1" ref="wrmsComponent" />
      <GraphWRMS dim="U" :graph_lim="[0,10]" id="graph_wrms_U" class="boxgraphInboxsection long_graph_1" ref="wrmsComponent" />
    </div>
  </section>


  <section class="boxsection boxgraph" id="ERP_residuals">
    <div class="boxtxtInboxsection">
      <h1>ERP residuals</h1>
      The figures below show the “AC – IGS” ERP residuals from the daily IGS SINEX combinations. The AC ERP residual time series are offset from each other for clarity.
      The corresponding time series are available <a href="ftp://igs-rf.ign.fr/pub/erp/">here</a>. 
    </div>

  <div v-if="!wrmsLoading" id ="graph_po_res" class="boxgraphInboxsection" >
    <div id="graph_po_res_X" class="dim_online"><Graph_res api_path="po_res" dim="X" :offset="0.2" :ratio="2"/></div>
    <div id="graph_po_res_Y" class="dim_online"><Graph_res api_path="po_res" dim="Y" :offset="0.2" :ratio="2" /></div>
  </div>

  <div v-if="!wrmsLoading" id="graph_por_res" class="boxgraphInboxsection">
    <div id="graph_por_res_X" class="dim_online"><Graph_res api_path="por_res" dim="X" :ratio="2" /></div>
    <div id="graph_por_res_Y" class="dim_online"><Graph_res api_path="por_res" dim="Y" :ratio="2" /></div>
  </div>

  <div v-if="!wrmsLoading" id="graph_lod_res" class="boxgraphInboxsection"><Graph_res api_path="lod_res" :offset="0.1"/></div>
</section>



  <section class="boxsection boxgraph" id="Apparent_geocenter_residuals_and_scale_residuals" >
    <div class="boxtxtInboxsection">
      <h1>Apparent geocenter residuals and scale residuals</h1>
      The figures below show the “AC – IGS” apparent geocenter residuals from the daily IGS SINEX combinations. The AC apparent geocenter residual time series are offset from each other for clarity.
      The corresponding time series are available <a href="ftp://igs-rf.ign.fr/pub/gc/">here</a> and <a href="ftp://igs-rf.ign.fr/pub/sc/">here</a>. 
    </div>

    <div v-if="!wrmsLoading" id="graph_gc_res" class="boxgraphInboxsection">
      <div id="graph_gc_res_X" class="dim_online"><Graph_res api_path="gc_res" dim="X"  :offset="4" :ratio="3" /></div>
      <div id="graph_gc_res_Y" class="dim_online"><Graph_res api_path="gc_res" dim="Y"  :offset="4" :ratio="3"/></div>
      <div id="graph_gc_res_Z" class="dim_online"><Graph_res api_path="gc_res" dim="Z"  :offset="4" :ratio="3"/></div>
    </div>
    <div v-if="!wrmsLoading" id="graph_sc_res" class="boxgraphInboxsection"><Graph_res api_path="sc_res" :offset="1" :ratio="1"/></div>
  </section>

  <FooterLogos/>

</template>

<script>

import GraphWRMS from '@/components/GraphWRMSDim.vue';
import Graph_res from '@/components/Graph_res.vue'
import MenuBoxText from '@/components/MenuBoxText.vue';
import FooterLogos from '@/components/FooterLogos.vue';

export default {
  name: 'DailyStatsView',
  components: {
    GraphWRMS,
    Graph_res,
    MenuBoxText,
    FooterLogos
},
data() {
    return {
      wrmsLoading: true // Initially set to true
    }
  
  },

mounted() {

  // Load WRMS graph 1st..
  const wrmsComponent = this.$refs.wrmsComponent;
  // Watch for changes to loading in the GraphWrms.vue component
  wrmsComponent.$watch('loading', newVal => {
      this.wrmsLoading = newVal;
       
    });
 
  },


unmounted () { //prevent leak of memories with to many Highcharts.charts
    this.$router.go(this.$router.currentRoute)
  }
};
</script>


<style>
 #graph_po_res #graph_por_res #graph_gc_res {
  display: flex;
  align-items: center;
  justify-content: center;
 }

</style>
