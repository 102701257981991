// ./components/FixedHeader.vue
<template>
    <div class="navbar">
      <!--<div class="image">
        <img src="../assets/logo-igs2.jpg" >
      </div>-->

      <div class="title">
        <h1 id="main_title">Terrestrial Frame Combination Center</h1>
        <h3>International GNSS Service</h3>
        <img src="" alt="">
      </div>      
    </div>
</template>
 
<script>
//import FixedHeader from 'vue-fixed-header';
 
export default {
  name: 'FixedHeader',
  /*components:{
    FixedHeader
    }*/
  }

//set main color + header size css
var r = document.querySelector(':root');
r.style.setProperty('--header-color', require('../assets/global.json').headerColor);

var headerHight = require('../assets/global.json').headerSize;
headerHight = String(headerHight)+"px";

r.style.setProperty('--headerHight', headerHight);

</script> 
 
<style>

.navbar {
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  height: var(--headerHight);
  width: 100%;
  background-color: var(--header-color);
  color: #ffffff;
  /*background-image: url("../assets/header_texture.png");*/
 
}

#main_title{
  color: #ffffff;
}

.title{
  position:relative;
  text-align: right;
  top:25%;
  margin-right: 150px;
  color: #ffffff;
}
</style> 