<template>
    <div class="loader"></div>
</template>


<script>
export default {
    name: "LoaderDiv",



    mounted () {
      //set main color + header size css
      var r = document.querySelector(':root');
      r.style.setProperty('--header-color', require('../assets/global.json').headerColor);
    }
}


</script>

<style>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--header-color);
  width: 25px;
  height: 25px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>



