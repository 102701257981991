<template>

<div class="menu_page">
  <nav class="navigation">
    <i>Page content:</i>
    <ul></ul>
  </nav>
</div>

</template>


<script>
import $ from 'jquery';

export default {
  name: 'MenuBoxText',

  methods : {
    build_menu(){
    //start by clear current Menu in case of persistent ref
    this.clearMenulist()
    // menu with main h1 title:
    var list = $('.menu_page ul');  
    var list_box = document.getElementsByClassName("boxsection")
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><
    // build nav menu
    for(let e=0; e< list_box.length; e++){
      var title_ref = list_box[e].getAttribute("id")
      var title = list_box[e].getAttribute("id").split("_").join(" ")

      //console.log(title_ref, title)
      
      //add list and <a> balise
      $(list).append('<li><a href="#' + title_ref + '">' +  title + '</a></li>');
      //console.log(e.getElementsByTagName("h1"))
    }
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><

    // Get all sections that have an ID defined
      const sections = document.querySelectorAll("section[id]");
      //console.log(sections)

      //active 1st section
      document.querySelector(".navigation a[href*=" + sections[0].getAttribute("id") + "]").classList.add("active");

    // Add an event listener listening for scroll
      window.addEventListener("scroll", this.navHighlighter);

    // add scoll event click menu
    // Add click event listener to the menu links
      var menuLinks = document.querySelectorAll('.navigation a');
      menuLinks.forEach((link) => {
        link.addEventListener('click', this.scrollToSection);
    });
    },
   
    scrollToSection(e) {// Scroll to the corresponding section
      e.preventDefault();
      
      var targetId = e.target.getAttribute('href');
      var targetElement = document.querySelector(targetId);

      if (targetElement) {
        window.scrollTo({
          behavior: 'smooth',
          top: targetElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top, //this method allows to keep header offset from top
          
        });
      }
    },

    navHighlighter() {
      // Get all sections that have an ID defined
    //var headerHight = require('@/assets/global.json').headerSize;
    const sections = document.querySelectorAll("section[id]");
  
    // Get current scroll position
    let scrollY = window.pageYOffset;
    
    
    // Now we loop through sections to get height, top and ID values for each
    sections.forEach(current => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - (200);
      const sectionId = current.getAttribute("id");
      
      //console.log(sectionTop, sectionTop + sectionHeight, sectionId, scrollY, sections)
      
      /*
      - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
      - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
      */
      if (
        (scrollY > sectionTop &&
        scrollY <= sectionTop + sectionHeight) ||
        (scrollY < sectionTop && $(window).scrollTop() + $(window).height() == $(document).height())
      ){
        document.querySelector(".navigation a[href*=" + sectionId + "]").classList.add("active");
      } else {
        document.querySelector(".navigation a[href*=" + sectionId + "]").classList.remove("active");
      }
    });

   
},

clearMenulist() {

  // Select all anchor elements with class "navigation"
  const navigationLinks = document.querySelectorAll('.navigation li');
  // Loop through each anchor element
  navigationLinks.forEach(elem => {
      // Select all div elements within the current anchor element
      elem.remove();
    });
}

  },
  mounted() {//method to build text page
    //default : scroll to top
    window.scrollTo(0, 0);
    this.build_menu()
},

}

//set main color + header size css
var r = document.querySelector(':root');
r.style.setProperty('--header-color', require('../assets/global.json').headerColor);
r.style.setProperty('--mainColor', require('../assets/global.json').mainColor);
// anchor not exactly at the top of the page with header size
var headerSize = require('../assets/global.json').headerSize;
var top_size = '-'+headerSize+'px'; //ex:-250px..
r.style.setProperty('--top-size', top_size);
//console.log(top_size);
</script>

<style>
.boxtxt {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 20px 50px;
    text-align: justify;
}

.boxtxtInboxsection{
  margin-bottom: 20px;
  padding: 20px 50px;
  text-align: justify;
}

.boxgraph {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 5px; 
    box-shadow: 3px 3px 11px 1px rgba(0, 0, 0, 0.36);

    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 10px 10px;
}

.boxgraphInboxsection {
  text-align: center;
  display: inline-block;
}



.boxsection{
    background-color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 5px; 
    box-shadow: 3px 3px 11px 1px rgba(0, 0, 0, 0.36);
    display: inline-block;


}

h1{
    color:var(--header-color);
    font-family:Avenir, Helvetica, Arial, sans-serif;
}

.menu_page{
    position: fixed;
    font-size: 14px;
    z-index: 9999999999;
    /*border-left: solid var(--header-color) 2px;*/
    left:0;
    bottom:10vh;
    margin-left: 60px;
    text-align: start;
    width: 187px;
    display: flex;
    flex-direction: column;    
}

.navigation {
  border: solid var(--mainColor) 2px;
  background-color: var(--mainColor);
  border-radius: 10px;
  z-index: 9999;
  color: white;
  font-size: 14px;
  padding: 7px;
}




nav ul {
  padding: 0;
}

nav li a {
  text-decoration: none;
  /*color: #333;*/
  color : white;
  display: block;
  font-weight: 400;
  transition: all ease-out 
    250ms;
  
  padding: 10px;
}

nav li a:hover {
  color:var(--header-color);
}

.active {
  color: var(--header-color); 
  font-weight: 600;
}


</style>
