import { createApp } from 'vue';
import App from './App.vue';
import {Tabs, Tab} from 'vue3-tabs-component';
//import 'leaflet/dist/leaflet.js';
//import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
//import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import router from './router';

//import Highcharts from 'highcharts'
//import More from 'highcharts/highcharts-more';
//import boost from 'highcharts/modules/boost';

const app = createApp(App);
app.use(router).component('tabs', Tabs).component('tab', Tab).component('EasyDataTable', Vue3EasyDataTable).mount('#app');

//More(Highcharts);
//boost(Highcharts);

//app.use(Highcharts);

