<template>

 <div ><i> 
    <LoaderDiv class="dim_online"/> <p class="dim_online">Chart is loading...</p>
 </i></div>
 
</template>


<script>
import $ from 'jquery';
import LoaderDiv from './LoaderDiv.vue';
import {syncTooltip, syncExtremes, syncExtremesY, verticaleLine} from './HighchartsTools.js';
import {zip_data, convertDecimalDate} from './APITools.js'


/* eslint-disable */
export default {
  name: "GraphWRMS",
  props: {
    "dim":{
      type:String,
      default:'E'},
    "graph_lim":{//lim E, N or H
      type:Array,
      default:[null,null]

  }},
  components: { LoaderDiv },
  data() {
		return {
        
      loading: false,
         // empty chartOptions
      chartOptions : {
          boost: {seriesThreshold : 2},
          chart: {zoomType: 'xy'},
          series: [],
          title: {text: ''},
          tooltip: {valueDecimals: 2, split: true, xDateFormat: "%a, %B %e, %Y"},
          xAxis:{
            crosshair:true,
            type: "datetime",
            events: {
                      setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremes(e, Highcharts, ["graph_wrms_"])
                      },
                    },
            plotLines:[]
          },
          yAxis:{
            max:this.graph_lim[1],
            title: {text: this.get_long_dim_name() + ' [mm]'},
            showEmpty: true,
            events: {
                setExtremes: (e)=>{
                        //with this syntax, apply my method imported from toolbox
                        syncExtremesY(e, Highcharts, ["graph_wrms_"])
                      },
                    },
          },

        }
		}
	},
  methods:{

 get_long_dim_name(){
    if (this.dim=='E'){
     return 'East'}
    else if (this.dim=='N'){
        return 'North'
    }else {return 'Up'}

 },

  plotGraphRes(response){
  //init

  var yswitch = response["yswitch"]
  //update graphes:
  for (let center=0; center < response['ac'].length; center++){ //for each dim
    
    // data from response
    var y = response["y"];
    var y = y.map(convertDecimalDate)

    //var ymin = - response["mv"][dim];
    //var ymax = response["mv"][dim];

    var residues = zip_data(y,response["r"][center]);


    this.chartOptions["series"].push({
        boostThreshold: 1,
        id: response["ac"][center],
        name: response["ac"][center],
        type:  'line',
        data: residues
        })
    
     
    //yswitch : verticale line
    this.chartOptions['xAxis']['plotLines'].push(verticaleLine(yswitch, 'repro3', 'right'))
    this.chartOptions['xAxis']['plotLines'].push(verticaleLine(yswitch, 'operational', 'left'))

    }

    var div_id = "graph_" + this.api_path + "_"+ this.dim
    Highcharts.chart("graph_wrms_"+this.dim, this.chartOptions)

  },
  },

  async mounted() {

    this.loading=true;
    var api_address = process.env.VUE_APP_api_address;

    var response = await fetch(api_address+"/wrms/"+this.dim, {
          method: 'get',
          headers:{
            "Access-Control-Allow-Origin": "*",
          }
        }).then(response => response.json());

      //console.log(response);
      this.plotGraphRes(response)
      this.loading=false;

      // SYNCHRONIZED CHART
      $('#container_graphes_wrms').bind("mousemove mouseleave", function(e) {syncTooltip(e, Highcharts, ["graph_wrms"])})
  }

}
</script>