<template>
  <div id="app">
  <div class="home">
    <div id="bg_texture"></div>
    <SideBarMenu />
    <FixedHeader />
    
    <router-view :key="$route.fullPath"/>
  </div>
</div>
</template>

<script>
//set main color + header size css
import SideBarMenu from './components/SideBarMenu.vue';
import FixedHeader from './components/FixedHeader.vue'
var r = document.querySelector(':root');
r.style.setProperty('--header-color', require('.//assets/global.json').headerColor);
r.style.setProperty('--marginTop', String(parseInt(require('./assets/global.json').headerSize))+"px");

export default {
    name: 'app',
    components: {
      SideBarMenu,
      FixedHeader
    },
  };
</script>

<style>
#app {
  height: 100%;
  font-family: 'Poppins', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #ffffff;*/
  background-image: url("./assets/bg_aliedocs_side.svg");
  background-size: 20%;
  background-repeat: repeat-y;
  
  
  /*background-image: url("./assets/bg_texture.png");*/
  
  margin-top: var(--marginTop);
};


html, body {
  height : 100%;
  width: 100%;
  margin: 0;
  
};
html {
  scroll-behavior: smooth;
}

.home{
  background-color: rgba(0, 118, 145, 0.070);
  /*background-color: #a8a3a33b*/
  
}


/*
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}*/

</style>
