<template>

    <div class="menu_page" style="margin-left: 15px; ">
      <div class="legendSta">
        <i>Color legend:</i>
        <ul>
            <li class="color_sta"><b>Stations part of IGS cumulative solution</b></li>
            <li class=""><b>Other stations (i.e. with short or sparse position time series)</b></li>
        </ul>
      </div>
    </div>
    
</template>

<script>
export default {
  name: 'LegendStations'
}
</script>



<style>

.legendSta {
  border: solid var(--mainColor) 2px;
  width: 222px;
  background-color: white;
  border-radius: 10px;
  z-index: 9999;
  color: rgb(0, 0, 0);
  font-size: 12px;
  padding: 7px;

}

.legendSta ul {
  list-style-type: square;
  list-style-position: inside;
}




</style>