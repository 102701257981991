<template>
<div class="boxsection boxtxt" id="list" v-for= "(values, name) in dictNames" :key="name">
  <h1>{{ name }}</h1>

  <div class="stationbox" v-for="v in values" :key="v[0]"> 
    <!-- repro3 station, blue color-->
    <div  v-if="v[1]" class="igscumul"> 
    <router-link :to="{ name: 'station_name', params:{ name_sta: v[0] }}">  
      <a >{{ v[0] }}</a>
    </router-link>
    </div>

    <!-- no repro3 station-->
    <div v-else class="other" >
    <router-link :to="{ name: 'station_name', params:{ name_sta: v[0] }}">  
      <a >{{ v[0] }}</a>
    </router-link>
    </div>
  </div>
  

</div>
</template>

<script>

//set main color + header size css
var r = document.querySelector(':root');
r.style.setProperty('--igs-color', require('../assets/global.json').igscumulstaColor);
r.style.setProperty('--otherSta-color', require('../assets/global.json').otherstaColor);

export default {
  name: 'ListStations',
  props: {
    msg: String
  },
  data() {
    return {
    dictNames: null
    }
  },
  async created() {

      var api_address = process.env.VUE_APP_api_address;

      var response = await fetch(api_address+"/stations", {
        method: 'get',
        headers:{
          "Access-Control-Allow-Origin": "*",
        }
      }).then(response => response.json())

      var arrayList = Object.keys(response);
      
  
      //create dict name
      var dictNames = {}; //empty
      for (const num in arrayList){ ///sorted in alphabetical order
      var name = arrayList[num];
        if (!(name[0] in dictNames)){//if key doesn't exist yet
          dictNames[name[0]]= [];// name[0]=1st letter 
        }
        dictNames[name[0]].push([name,response[name]])
      }

      this.dictNames = dictNames;

  }
}


</script>


<style >
.stationbox {
    background-color: var(--header-color);
    border: 2px solid var(--header-color);
    border-radius: 5px; 
    box-shadow: 3px 3px 11px 1px rgba(0, 0, 0, 0.36);

    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    
    text-align: center;
    color: #ffffff;
    display: inline-flex;
    text-decoration: none;
}

.stationbox a{
  text-decoration: none;
  color : #ffffff
}

.stationbox :hover{
  background-color: #90d7f3;   
}

.igscumul {
  background-color: var(--igs-color);
  border: 2px solid var(--igs-color);
}

.other {
  background-color: var(--otherSta-color);
  border: 2px solid var(--otherSta-color);
}

#list{
  text-align: center
}

.txtlink {
  color: #ffffff
}

</style>
