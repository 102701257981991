<template>

    <fieldset >
    
    <div class="wrapper_form_crd">
        <p style="display:inline">Solution:  </p>
        <select v-model="solution" class="form_comp">
            <option v-for="opt in sol_list_options" :key="opt"> {{ opt }}</option>
        </select>
        

        <p style="display:inline">Type of coordinates:  </p>
        <select v-model="type_of_coordinates" @change="changeTypeCoord" class="form_comp">
            <option v-for="opt in type_coord_list_options" :key="opt"> {{ opt }}</option>
        </select>
        
        
        <p id="text_epoch" style="display:inline; margin-right: 20px;"> Epoch (for instantaneous position only):  </p>
        <input id="input_epoch" type="date" v-model="epoch" class="form_comp"/>
        
        
        <p style="display:inline">Format:  </p>
        <select v-model="format" class="form_comp">
            <option v-for="opt in format_list_options" :key="opt">{{ opt }}</option>
        </select>
    </div>

    <div style="text-align: center; padding: 10px;" >   
        <button @click="confirmSubmit()" id="b_submit_formStaCoords" class="form_comp">Submit</button>
    </div>

    </fieldset>

    <div id="result_form">
        <div id="buttons_txtForm" hidden>
            <button id="b_download_txtForm" class ="b_txtForm "  @click="downloadForm()" title="Download file"></button>
            <button id="b_clear_txtForm" class ="b_txtForm" @click="clearForm()" title="Clear form"></button>
        </div>
        <div id="box_form_result"></div>
    </div>

</template>

<script>

export default {
    name: "FormStaCoords",
    props:["name_sta", "sol_list_options"],
    data() {
        return {
            api_address : process.env.VUE_APP_api_address,
            type_coord_list_options: ["Instantaneous position","Full kinematic model"],
            format_list_options: ["Table","SINEX"],
            solution: "", type_of_coordinates: "", epoch: "", format: "",
            today : new Date().toISOString().split('T')[0]
        }
    },

    methods: {

        changeTypeCoord(event) { 
            /**
             * Disable epoch tool if user choose 'kinematic' option
             */
            
            let diasb
            if (event.target.value != "Instantaneous position"){
                diasb = true
                document.getElementById("text_epoch").style.color = "grey"
            }
            else{
                diasb = false
                document.getElementById("text_epoch").style.color = "black"
            }
            document.getElementById("input_epoch").disabled = diasb
            
        },

        printFormResult(text){
            //break line : replace \n by <br>
            //text = text.replace('\n','<br>')

            var box  = document.getElementById("box_form_result");
            box.innerHTML = "" //clean box
            var lines = text.split("\n") // break line is '\n'
            
            // download button & clear button visible
            var div_buttons = document.getElementById('buttons_txtForm')
            div_buttons.hidden = false
            

            for (var i = 0; i < lines.length; i++){
                box.innerHTML+= lines[i] + "<br>" //break line
            }
        },

        downloadForm(){
            /**
             * Generate file to download
             */
            var box  = document.getElementById("box_form_result");
            //replace <br> by \n in text file     
            const blob = new Blob([box.innerHTML.replace(/<br\s*?>/gi,"\n")]);
            const fileUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = this.name_sta+"_crd.txt";
            link.href = fileUrl;
            link.click()
        },

        clearForm(){
            /**
             * Clear form if too long...
             */
            var box  = document.getElementById("box_form_result");
            box.innerHTML = ""
            var div_buttons = document.getElementById('buttons_txtForm')
            div_buttons.hidden = true
        },


        async confirmSubmit() {
            /**
             * API request + file plot in div
             */
            
            //change in DB
            var post_dict = {
                "solution": this.solution, 
                "type_of_coordinates":this.type_of_coordinates,
                "epoch":this.epoch,
                "format":this.format.toLowerCase(), //Table -> table & Sinex-> sinex
                "station":this.name_sta
                }

            //api format for "type_of_coordinates" : "epoch" or "kinematic"
            if (post_dict["type_of_coordinates"].includes("kinematic")){
                post_dict["type_of_coordinates"] = "kinematic"
            } else {
                post_dict["type_of_coordinates"] = "epoch"
            }

            if (post_dict["solution"].includes("cumulative")){
                post_dict["solution"] = "IGS" //api format for 'cumulative'
            }

            if (post_dict["epoch"].length == 0){ // no day selected by user : ''
                //default : today
                post_dict["epoch"] = this.today
            }

            // API request
            var response = await fetch(this.api_address+"/extract_crd/", {
                method: 'post',
                body: JSON.stringify(post_dict),
                
            }).then(response => response.text())

            //add text to box result div
            this.printFormResult(response)
        },

    },

    mounted () {

        this.solution = this.sol_list_options[0]
        this.type_of_coordinates = this.type_coord_list_options[0]
        this.format = this.format_list_options[0]

        //default date to today
        this.epoch = this.today
    },


  
}
</script>

<style>

.wrapper_form_crd {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 5px;
}

#result_form{
    position: relative
}

#box_form_result{
    
    background-color: white;
    font-family: monospace;
    white-space: pre-wrap; /* preserv white blank*/
}


#buttons_txtForm{
    position : absolute;
    right:0;
}

.b_txtForm{
    
    background-color: white;
    background-size: cover;
    width: 30px;
    height: 30px;
    cursor:pointer;
    
}

#b_download_txtForm{
    background-image: url('../assets/download.png')
}

#b_clear_txtForm{
    background-image: url('../assets/clear.png')
}


.form_comp {
	box-shadow: 0px 10px 14px -7px #276873;
	background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
	background-color:#599bb3;
	border-radius:8px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	
	font-size:16px;
	font-weight:bold;
	padding:5px 32px;
	text-decoration:none;
	text-shadow:0px 1px 0px #3d768a;
}
.form_comp:hover {
	background:linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
	background-color:#408c99;
}
.form_comp:active {
	position:relative;
	top:1px;
}

.form_comp:disabled {
    background:#8c8e8f;
    cursor: not-allowed;

}


</style>
